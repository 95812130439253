<template>
  <div class="px-2 auth-wrapper auth-v1">
    <div class="py-2 auth-inner">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="ml-1 brand-text text-primary"></h2>
        </b-link>
        <div>
          <b-alert
            v-if="verifyFail"
            variant="warning"
            class="mb-1"
            :show="verifyFail"
          >
            <div class="alert-body">
              <b-card-title>
                <div>Oops! Your email is still not verified.</div>
              </b-card-title>
            </div>
          </b-alert>

          <b-card-title v-else class="mb-1">
            <div>
              Congratulations
              {{ displayName != "none none" ? displayName : "" }}!
            </div>
          </b-card-title>
          <b-card-text class="mb-2">
            <div>
              <b>Please verify your email</b>
            </div>
            <div class="p-1">
              Click on the link in the email we sent you. After you have
              verified, just click the "Continue" button below.
            </div>
          </b-card-text>
        </div>

        <!-- form -->

        <!-- submit button -->
        <div>
          <div>
            <b-button
              v-if="loading"
              variant="primary"
              class="min-w-full"
              @click="success()"
            >
              <b-spinner small type="grow"></b-spinner>
              Checking ...
            </b-button>
            <b-button
              v-else-if="verifyFail"
              variant="warning"
              class="min-w-full"
              @click="success()"
            >
              Verified? Continue ...
            </b-button>
            <b-button
              v-else
              variant="primary"
              class="min-w-full"
              @click="success()"
            >
              Continue to Account Setup
            </b-button>
          </div>
          <b-card-text class="mt-2 text-center" @click="resendEmail()">
            <b-link>
              Resend Email
            </b-link>
          </b-card-text>
        </div>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
//import { ValidationProvider, ValidationObserver } from "vee-validate"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import firebase from "firebase/app"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import "firebase/auth"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BAlert,
  BSpinner,
  //BFormGroup,
  //BFormInput,
  //BForm,
  BButton
} from "bootstrap-vue"
import { required, email } from "@validations"

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BAlert,
    BSpinner,
    //BFormGroup,
    //BFormInput,
    BButton
    //BForm,
    //ValidationProvider,
    //ValidationObserver
  },
  data() {
    return {
      userEmail: "",
      loading: false,
      displayName: this.$store.getters["auth/userData"]["fullName"],
      verifyFail: false,
      // validation
      required,
      email
    }
  },
  created() {
    this.resendEmail(true)
  },
  methods: {
    async success() {
      const l1 = this

      l1.loading = true
      var i = 0
      var authorized = false

      do {
        if (i === 10 || i === 20 || i === 30 || i === 40 || i === 50) {
          await firebase.auth().currentUser.reload()
          authorized =
            (await firebase.auth().currentUser.emailVerified) || false
        }
        i++
      } while (i < 60 && !authorized)

      l1.loading = false

      if (!authorized) {
        l1.verifyFail = true
      } else {
        l1.verifyFail = false

        //await l1.$store.dispatch("auth/updateEmailVerified", true)

        const userRole = await l1.$store.getters["auth/role"]
        const homeRoute = getHomeRouteForLoggedInUser(userRole)

        l1.$router
          .push(homeRoute)
          .then(() => {
            l1.$router.push(homeRoute)
          })
          .catch(() => {
            l1.$router.push(homeRoute)
          })
      }
    },
    resendEmail(initial = false) {
      if (!initial) {
        this.$toast({
          component: ToastificationContent,
          position: "top-center",
          props: {
            title: "Email Sent",
            icon: "MailIcon",
            variant: "success",
            text: `We have sent you a new verification email. Check your email now.`
          }
        })
      }
      firebase.auth().currentUser.sendEmailVerification()
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
